import TimeEntryCommittedEntity from "../time_entry_committed/entity";
import Utils from "../../../utils";
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import DefaultController from "../../defaultController";


export default class TeamsHome extends DefaultController {
    private absenceModal: any;
    private absenceTable: any
    private tableElem: any;

    private correctionTable: any
    private tableElemCorrection: any;

    private tripTable: any
    private tableElemTrip: any;
    private editModal: any;

    async init() {
        this.entity = "absences"
        this.tableElem = jQuery(".teams_home_absences_pending")
        this.tableElemTrip = jQuery('.teams_home_trips_pending')
        this.tableElemCorrection = jQuery('.teams_home_corrections_pending')
        this.editModal = new bootstrap.Modal((document.querySelector("#showTimeEntryCommitted") as HTMLElement));
        this.tableElem.delegate(".delete-record", "click", async (e: any) => {const confirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary me-3',
                cancelButton: 'btn btn-label-secondary'
            },
            buttonsStyling: false
        })
            if(confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id")
                const r = await Utils.entity.destroy(id, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('absence.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('absence.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                await this.request("")
                console.log("this.absenceTable", this.absenceTable)
            }
        });

        this.tableElemTrip.delegate(".accept-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const r = await Utils.entity.upsert({uuid: id, status: "ACCEPTED"}, "trips")
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('trip.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
            } else {
                this.toastr.error(`${Utils.translate('trip.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
            }
            this.tripTable.ajax.reload();
        });
        this.tableElemTrip.delegate(".decline-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const r = await Utils.entity.upsert({uuid: id, status: "DECLINED"}, "trips")
            if (r.status === 200) {
                this.toastr.success(`${Utils.translate('trip.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
            } else {
                this.toastr.error(`${Utils.translate('trip.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
            }
            this.tripTable.ajax.reload();
        });


        this.setupEventListeners();

        await this.setupDatatables();
        if (activeUser.members && activeUser.members.length > 0) {

            await this.setupDatatableCorrections();
            await this.setupDatatableTrips();
        }
        await this.request("");
        this.bindListeners()
        this.bindListenersCorrections()
        Utils.hideLoader()
    }

    protected bindListeners() {
    }

    bindListenersCorrections() {
        this.tableElemCorrection.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, "time_entry_committeds")
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement);
                await Utils.updateElements(data, '', form)
                const oldConfig = Utils.timeTrack.generateList(data.config || []);
                const oldOverview = Utils.timeTrack.generateOverview(data.working, data.planned, data.pause, data.absences);
                this.editModal._element.querySelector("#time_entry_committed_old_config .time_entry_config").innerHTML = oldConfig;
                this.editModal._element.querySelector("#time_entry_committed_old_config .timeline-overview").innerHTML = oldOverview;
                if (data.status === "CORRECT_REQUESTED") {

                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.remove("col-6");
                    this.editModal._element.querySelector("#time_entry_committed_new_config").classList.remove("d-none");
                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.add("col-12");

                    const newConfig = Utils.timeTrack.generateList(data.config_new_calculated.config)
                    const newOverview = Utils.timeTrack.generateOverview(data.config_new_calculated.working, data.config_new_calculated.planned, data.config_new_calculated.pause, data.absences);
                    this.editModal._element.querySelector("#time_entry_committed_new_config .time_entry_config").innerHTML = newConfig;
                    this.editModal._element.querySelector("#time_entry_committed_new_config .timeline-overview").innerHTML = newOverview;

                    this.editModal._element.querySelector("#time_entry_committed_config").value = JSON.stringify(data.config_new_calculated);

                    Utils.timeTrack.liveEdit(this.editModal._element.querySelector("#time_entry_committed_new_config .time_entry_config"), new Date(Date.parse(data.date)));
                } else {

                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.add("col-6");
                    this.editModal._element.querySelector("#time_entry_committed_new_config").classList.add("d-none");
                    this.editModal._element.querySelector("#time_entry_committed_messages").classList.remove("col-12");
                    let html = '';
                    data.reasoning.forEach((reason: any) => {
                        let message = ''
                        if (reason["key"] === "MAX_HOURS_EXCEEDED") {
                            message = `Maximale Arbeitszeit überschritten. <br />${Utils.translate("timetrack.expected")}: ${Utils.msToHMS(parseInt(reason["expected"]) * 1000)}<br /> ${Utils.translate("timetrack.actual")}: ${Utils.msToHMS(parseInt(reason["actual"]) * 1000)}`
                        } else if (reason["key"] === "STAMP_TOO_EARLY" || reason["key"] === "STAMP_TOO_LATE") {
                            message = `${Utils.translate(`timetrack.${reason["key"].toLowerCase()}`)} <br />${Utils.translate("timetrack.expected")}: ${reason["expected"]}<br /> ${Utils.translate("timetrack.actual")}: ${reason["actual"]}`
                        }
                        html += `<div class="alert alert-danger" role="alert">${message}</div>`
                    })
                    this.editModal._element.querySelector("#time_entry_committed_messages .client_messages").innerHTML = html;
                    Utils.timeTrack.liveEdit(this.editModal._element.querySelector("#time_entry_committed_old_config .time_entry_config"), new Date(Date.parse(data.date)));
                }
                this.editModal.show();
            }
        });
        (document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const mode = (e.submitter as HTMLButtonElement).getAttribute("data-mode");
            const elem = document.querySelector("#showTimeEntryCommittedForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            console.log("mode", mode)
            if (valid) {
                const r = await Utils.entity.upsert({...this.getEntityData(elem), status: mode, reasoning: []}, "time_entry_committeds")
                if (r.status === 200) {
                    await this.correctionTable.ajax.reload();
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('time_entry_committed.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }

    getEntityData(elem: any) {
        return TimeEntryCommittedEntity.getEntityData(elem)
    }
    setupDatatables() {
        let columns = [
            {data: 'from'},
            {data: 'to'},
            {data: 'name'},
            {data: 'uuid'}
        ];
        let columnDefs = [
            {
                targets: 0,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 1,
                render: (data: any, type: any, full: any, meta: any) => {
                    return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                },
            },
            {
                targets: 2,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (!full.absence_mode && !full.absence_mode.name){
                        console.warn("Absence mode not found!", full)
                        return "";
                    } else {
                        return full.absence_mode.name
                    }
                },
            },
            {
                targets: 3,
                searchable: false,
                orderable: false,
                render: (data: any, type: any, full: any, meta: any) => {
                    if (full.status === "NEW" || (window.activeUser && window.activeUser.role === "admin")) {
                        return `<div class="d-flex align-items-center justify-content-center">
                                  <a href="javascript:void(1)" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                    } else if (full.status){
                        return Utils.translate(`absence.status.${full.status.toLowerCase()}`);
                    } else {
                        return ""
                    }
                },
            }
        ]

        if (activeUser.members && activeUser.members.length > 0) {
            columns = [
                {data: 'from'},
                {data: 'to'},
                {data: 'name'},
                {data: 'user.name'},
                {data: 'uuid'}
            ]
            columnDefs = [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (!full.absence_mode && !full.absence_mode.name){
                            console.warn("Absence mode not found!", full)
                            return "";
                        } else {
                            return full.absence_mode.name
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user.name
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status === "NEW" || (window.activeUser && window.activeUser.role === "admin")) {
                            return `<div class="d-flex align-items-center justify-content-center">
                                  <a href="javascript:void(1)" data-id="${data}" class="text-body delete-record">
                                    <i class="ti ti-trash ti-sm mx-2"></i>
                                  </a>
                                </div>`
                        } else if (full.status){
                            return Utils.translate(`absence.status.${full.status.toLowerCase()}`);
                        } else {
                            return ""
                        }
                    },
                }
            ]
        }
        console.log("columnDefs", columnDefs)
        this.absenceTable = new DataTable(this.tableElem, {
            ajax: `/api/v1/absences`,
            columns: columns,
            order: [[0, 'desc']],
            columnDefs: columnDefs,
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                // @ts-ignore
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2',
                    action: async () => {
                        let csv = '';
                        const csvHeader: any = []
                        this.absenceTable.columns().header().each((column: any, i: number) => {
                            const visible = this.absenceTable.column(i).visible();
                            if (visible) {
                                let content = column.innerHTML
                                if (content.indexOf("<span") !== -1) {
                                    content = content.match(/>(.*)<\/span><span/)[1]
                                }
                                if (content !== "Actions") {

                                }
                                csvHeader.push(content)
                            }
                        })
                        csv += `${csvHeader.join(";")}\n`
                        this.absenceTable.rows().data().each((data: any) => {
                            console.log(data)
                            let content = [new Date(Date.parse(data.from)).toLocaleDateString(),
                                new Date(Date.parse(data.to)).toLocaleDateString(),
                                data.absence_mode.name
                            ]
                            csv += content.join(";") + "\n";
                        })

                        await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                    }
                }
            ]
        });
    }

    async setupDatatableCorrections() {
        this.correctionTable = new DataTable(this.tableElemCorrection, {
            initComplete: () => {
                this.tableElemCorrection.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElemCorrection.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/time_entry_committeds`,
            columns: [
                {data: 'date'},
                {data: 'uuid'},
                {data: 'status'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user.name;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (data === "PENDING") {
                            return "Erfordert Genehmigung"
                        }else if (data === "CORRECT_REQUESTED") {
                            return "Änderung angefragt"
                        }
                    },
                },
                {
                    targets: 3,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="text-body edit-record">
                                    <i class="ti ti-eye ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
    async setupDatatableTrips() {
        this.tripTable = new DataTable(this.tableElemTrip, {
            initComplete: () => {
                this.tableElemTrip.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElemTrip.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/trips?status_name=new`,
            columns: [
                {data: 'uuid'},
                {data: 'date'},
                {data: 'distance'},
                {data: 'transportation'},
                {data: 'uuid'},
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.user.name;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${data}</span>${new Date(Date.parse(data)).toLocaleDateString()}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${data}km`;
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return data;
                    },
                },
                {
                    targets: 4,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="#" data-id="${data}" class="accept-record btn btn-success" style="margin-right: 5px;">
                                    <i class="ti ti-circle-check-filled ti-sm"></i>
                                  </a>
                                  <a href="#" data-id="${data}" class="decline-record btn btn-danger">
                                    <i class="ti ti-circle-x-filled ti-sm"></i>
                                  </a>
                                </div>`
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',

            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }
    async setupEventListeners() {
        const prev = document.querySelector("#teams_home_absence_prev") as HTMLElement;
        const next = document.querySelector("#teams_home_absence_next") as HTMLElement;
        prev.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("minus")
        });

        next.addEventListener("click", async (e: any) => {
            e.preventDefault();
            await this.request("plus")
        })

    }

    async request(mode: string) {
        const table = document.querySelector("#teams_home_absence_table") as HTMLElement
        const yearDisplay = (document.querySelector("#teams_home_absence_date") as HTMLButtonElement);
        // @ts-ignore
        const currentDate = parseInt(yearDisplay.getAttribute("data-date"));
        let newDate = currentDate
        if (mode === "minus") {
            newDate = currentDate - 1
        }
        if (mode === "plus") {
            newDate = currentDate + 1
        }
        const r = await Utils.entity.request(`/api/v1/absences/info?year=${newDate}`, 'GET')
        if (r.status === 200) {

            yearDisplay.innerHTML = `${newDate}`;
            yearDisplay.setAttribute("data-date", `${newDate}`);
            table.innerHTML = `<tr>
                  <td><b>${Utils.translate('absence.vacation_days')}</b></td>
                  <td>${r.data.allowance}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.pending_days')}</b></td>
                  <td>${r.data.pending}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.approved_days')}</b></td>
                  <td>${r.data.taken}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.left_days')}</b></td>
                  <td>${r.data.left}</td>
                </tr>
                <tr>
                  <td><b>${Utils.translate('absence.over_hours')}</b></td>
                  <td>${r.data.over_hours}</td>
                </tr>`
            /*if (this.absenceTable) {
                this.absenceTable.clear();
                this.absenceTable.rows.add(r.data.absences);
                this.absenceTable.draw();
            }*/
        }
    }
}